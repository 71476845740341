<template>
  <div class="index">
    <div class="top" :style="topNode">
      <div class="record" @click="record">领取记录</div>
      <div class="conts">
        <div class="conts-title">
          <div class="conts-title-crown" :style="contsTitleNode"></div>
          <div class="conts-title-text">
            <span
              class="conts-title-text-icon left"
              :style="contsTitleLeft"
            ></span>
            <span class="conts-title-text-text">以下特权任选一</span>
            <span
              class="conts-title-text-icon right"
              :style="contsTitleRight"
            ></span>
          </div>
        </div>
        <ul class="conts-lists">
          <li
            @click="prerogative(item, index)"
            class="conts-lists-item"
            :class="{choiced:index === current}"
            v-for="(item, index) in iconList"
            :key="index">
            <div
              class="conts-lists-item-icon"
              :style="{
                backgroundImage: 'url(' + item.imgPath + ')',
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                borderRadius: '50%',
                transform: 'scale(.9)',
              }"
            ></div>
            <div class="conts-lists-item-text">
              <p>{{ item.text }}</p>
              <p>会员月卡</p>
            </div>
          </li>
        </ul>
        <div class="conts-choice">
          <span class="conts-choice-icon"></span>
          <span class="conts-choice-text">{{ getText }}</span>
        </div>
        <van-form
          name="form"
          class="form-box"
          validate-first
          @submit="receiveMember">
          <van-field
            class="single-field"
            v-model="this.phone"
            clearable
            placeholder="  请输入手机号码"
            maxlength="11"
            disabled
            :rules="[
              { required: true },
              { pattern: /^1[3456789]\d{9}$/, trigger: 'onBlur' },
            ]">
            <van-icon
              class="iconfont"
              slot="left-icon"
              class-prefix="icon"
              name="shouji"
              size="1.6em"
            />
          </van-field>
          <br/>
          <van-field
            v-if="hide"
            class="single-field"
            v-model="info.qqNum"
            clearable
            left-icon="shouji"
            placeholder="  务必输入正确QQ号码"
            maxlength="11"
            :rules="[
              { required: true },
              { pattern: /[1-9][0-9]{4,10}/, trigger: 'onBlur' },
            ]">
            <van-icon
              class="iconfont"
              slot="left-icon"
              class-prefix="icon"
              name="QQ"
              size="1.7em"
            />
          </van-field>
          <van-button
            native-type="submit"
            type="info"
            class="select-btn"
            size="large"
            round
            color="#d69d4c"
            >确认领取</van-button
          >
        </van-form>
      </div>
    <div class="rules">
      <div class="rules-lists">
        <div class="rules-title">· 权益购买须知 ·</div>
        <div class="rules-text">
          <p class="rules-txt">
            1、每个手机号码限购1份，下单后立即激活会员特权。购买时务必填写正确的手机号码，如因手机号填写错误导致充值错误，由消费者自行承担。
          </p>
          <p class="rules-txt">2、本产品一经购买，不退不改，介意者勿拍！</p>
          <p class="rules-txt">
            3、下单前请您务必仔细阅读产品中各项说明，然后再下单订购
          </p>
          <p class="rules-txt">
            4、下单购买后，则视为您已仔细阅读本产品中的各项说明，并认同此《购买须知》以及产品规则，否则责任自负，不在售后服务之列。
          </p>
        </div>
      </div>
      <div class="rules-lists">
        <div class="rules-title">· 九大权益使用流程 ·</div>
        <div class="rules-text">
          <p class="rules-txt">
            1、使用方法：购买会员成功后，即可兑换9大影音APP的特权VIP之一，通过兑换成功的手机号码登录对应的
            影音APP/官网，即可享受VIP权益。
          </p>
          <p class="rules-txt">
            2、使用规则:购买会员成功后，共有12个周期兑换VIP权益(即购买后一年内有12次权益兑换机会)
            : 每30天为一个周期，
            每个周期内可以兑换一次9大会员里任意一个VIP权益，指定周期内如未兑换视为过期，
            放弃当月兑换资格，下一周期可继续领取。
          </p>
          <p class="rules-txt">
            3、每30天兑换权益必须要自己去兑换，可以12个周期连续兑换一种，也可以兑换不同权益。
            无论换不换会员，每月都是需要去兑换，未兑换视为放弃兑换资格。
          </p>
        </div>
      </div>
      <div class="rules-lists">
        <div class="rules-title">· 注意事项 ·</div>
        <div class="rules-text">
          <p class="rules-txt">1、每个周期每次兑换会员时只能选择一个会员;</p>
          <p class="rules-txt">
            2、每次选择会员时务必填写购买时的手机号码;
            腾讯视频、QQ音乐、QQ会员要求填写正确的QQ号码，该三项权益要求通过QQ账号登录;
          </p>
          <p class="rules-txt">
            3、本商品只能在手机、电脑、ipad上使用，均非TV版;
          </p>
          <p class="rules-txt">4、若24小时后还未激活，请及时联系客服;</p>
          <p class="rules-txt">
            5、每月9个会员可相互转换/持续兑换一种，每月限转1次;
          </p>
          <p class="rules-txt">
            6、仅限注册会员购买使用，无法为其他手机号购买，可领取的权益均非TV版;
          </p>
          <p class="rules-txt">
            7、套餐内容如有变动或需要套餐外增值服务，费用自理，详情请咨询客服。
          </p>
          <br />
        </div>
      </div>
    </div>
    </div>
    <van-popup
      :close-on-click-overlay="false"
      v-model="tipShow"
      :style="{
        width: '85%',
        height: '42%',
        borderRadius: '12px',
        padding: '48px 30px 0px 30px',
      }"
      closeable>
      <div class="popipContent">
        <div class="item title">温馨提醒</div>
        <div class="item">
          您领取的是 <span class="note">{{ text }}</span
          >会员
        </div>
        <div class="item">
          QQ号码：<span class="note">{{ info.qqNum }}</span>
        </div>
        <div class="item remark">注：一旦领取不可退改</div>
        <div class="item btn">
          <van-button size="small" type="danger" @click="cancel"
            >返回修改</van-button
          >

          <van-button size="small" type="info" @click="receiveVip"
            >确认领取</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Tip from "../components/Message";
import { mapState,mapActions } from "vuex";

export default {
  name: "Index",
  components: {
    Tip,
  },
  computed: {
    ...mapState(["token", "eligibilityId", "phone"]),
  },
  data() {
    return {
      qqNum: "",
      current: null,
      PhoneNumber: null,
      hide: false,
      isActive: false,
      getText: "选择要领取的特权 ",
      text: null,
      num: 0,
      id: "",
      info: {
        phone: "",
        getText: "选择要领取的特权",
        qqNum: "",
      },
      topNode: {
        backgroundImage: "url(" + require("../assets/image/bg.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundColor: "#fff5ea",
      },
      contsTitleNode: {
        backgroundImage:
          "url(" + require("../assets/image/conts-title.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto 100% ",
        backgroundPosition: "center center",
        height: "2rem",
        marginBottom: "0.3rem",
        transform: "scale(0.9)",
      },
      contsTitleLeft: {
        width: "5rem",
        height: "1.5rem",
        transform: "scale(0.7)",
        backgroundPosition: "0 0",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" + require("../assets/image/left.png") + ")",
      },
      contsTitleRight: {
        width: "5rem",
        height: "1.5rem",
        transform: "scale(0.7)",
        backgroundPosition: "0 0",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" + require("../assets/image/right.png") + ")",
      },
      iconList: [
        {
          imgPath: require("../assets/image/qq_vip.png"),
          text: "QQ",
          qqNum: true,
          id: "1377542350956220427",
        },
        {
          imgPath: require("../assets/image/qq_music.png"),
          text: "QQ音乐豪华",
          qqNum: true,
          id: "1377542350956220428",
        },
        {
          imgPath: require("../assets/image/aiqiyi.png"),
          text: "爱奇艺视频",
          id: "1377542350956220429",
        },

        {
          imgPath: require("../assets/image/kugou.png"),
          text: "酷狗音乐豪华",
          id: "1377542350956220430",
        },
        {
          imgPath: require("../assets/image/mangguo.png"),
          text: "芒果TV",
          id: "1377542350956220431",
        },
        {
          imgPath: require("../assets/image/tengxun.png"),
          text: "腾讯视频VIP",
          qqNum: true,
          id: "1377542350956220432",
        },
        {
          imgPath: require("../assets/image/wangyiyun.png"),
          text: "网易云音乐",
          id: "1377542350956220433",
        },
        {
          imgPath: require("../assets/image/ximalaya.png"),
          text: "喜马拉雅巅峰",
          id: "1377542350956220434",
        },
        {
          imgPath: require("../assets/image/youku.png"),
          text: "优酷黄金VIP",
          id: "1377542350956220435",
        },
      ],
      rulesList: [
        {
          title: "·权益购买规则· ",
        },
      ],
      // 领取相关会员权益请求数据
      receiveForm: {
        token: "",
        phone: "",
        account: "",
        eligibilityId: "",
        vipId: "",
      },
      // 领取记录下 手机登录的弹窗（默认不显示）
      loginShow: false,
      showColor: "#a7a7a7",
      loginPhone: "",
      disablebtn: true,
      tag:true,

      // 验证手机号码请求数据
      validateForm: {
        token: "",
        phone: "",
      },
      tipShow :false
    };
  },
  activated(){
    this.loginShow = false
  },
  methods: {
    ...mapActions(['asyncSetToken','asyncSetEligibilityId','asyncSetPhone']),
    // 领取记录触发的方法
    record() {
      // 判断是否有登录的手机号码
      if (this.phone) {
        // 跳转至领取详情页面
        this.$router.push({
          path: `/record/${this.phone.replace(/\s/g, "")}`,
        });
      } else {
        // 弹出输入手机号码的框
        this.loginShow = true;
      }
    },
    // 判断用户点击的是哪个权益会员
    prerogative(item, index) {
      if (item.text) {
        this.id = item.id;
        this.text = item.text;

      }
      this.current = index;
      this.getText = `领取${item.text}会员月卡`;
      this.getTexts = `${item.text}会员`;
      item.qqNum ? (this.hide = true) : (this.hide = false);

      if (!item.qqNum) {
        this.info.qqNum = "";
      }
    },
    cancel() {
      this.tipShow = false;
      this.tag = true;
    },
    // 调用领取会员接口
    receiveVip() {
        this.receiveForm.token = this.token
        this.receiveForm.phone = this.phone
        this.receiveForm.eligibilityId = this.eligibilityId
        this.receiveForm.vipId = this.id;
        if(this.info.qqNum!==''){
              this.receiveForm.account = this.info.qqNum
          }else{
            this.receiveForm.account = this.phone
          }
        this.axios
          .post("/orders/placeOne", this.receiveForm, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((res) => {
            let messages = res.data.data.message;
            let codes = res.data.data.code;
            if (codes === 30000) {
              this.$dialog
                .alert({
                  title: "领取成功",
                  message: messages,
                })
                .then(() => {
                  this.tipShow = false;
                  this.tag = true
                });
            } else {
              this.$dialog
                .alert({
                  title: "领取失败",
                  message: messages,
                })
                .then(() => {
                  this.tipShow = false;
                  this.tag = true
                });
            }
          })
          .catch((res) => {
            console.log(res);
          });
    },

    // 领取会员按钮
    receiveMember() {
      if(!this.id){
        this.$notify("请选择权益");
        return;
      }
      if(this.tag){
        this.tag = false

        if(this.info.qqNum) {
          this.tipShow = true
        }else {
          this.tipShow = false
          this.receiveVip()
        }

      }
    },

  },

  watch: {
    loginPhone(newphone) {
      let p = /^1[3456789]\d{9}$/;
      newphone.length == 11 && p.test(newphone)
        ? ((this.showColor = "#d69d4c"), (this.disablebtn = false))
        : ((this.showColor = "#a7a7a7"), (this.disablebtn = true));
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  box-sizing: border-box;
}
.top {
  padding: 7.5rem 0 1rem;

  .record {
    position: fixed;
    right: 0;
    top: 1.1rem;
    color: #fff;
    padding: 0.3rem 0.55rem 0.3rem 0.3rem;
    font-size: 0.6rem;
    background-color: rgba(126, 88, 42, 0.7);
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
  }

  .conts {
    margin: 1.5rem 0.7rem 0;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 0.15rem 0.75rem 0 rgb(211 155 93 / 81%);

    .conts-title {
      padding-top: 0.4rem;

      .conts-title-text {
        text-align: center;

        .conts-title-text-text {
          color: #7a4528;
          font-size: 0.95rem;
          font-weight: 600;
          margin: 0 0.2rem;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .conts-lists {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0.6rem;
      margin-bottom: 0.9rem;

      .conts-lists-item {
        width: 32%;
        height: 3.65rem;
        padding-top: 0.4rem;
        padding-bottom: 3rem;
        border-radius: 0.3rem;

        &.choiced {
          background-color: #fff5ea;
        }

        .conts-lists-item-icon {
          width: 2.8rem;
          height: 2.8rem;
          margin: 0 auto 0.35rem auto;
        }

        .conts-lists-item-text {
          color: #222;
          font-size: 0.75rem;
          text-align: center;

          p {
            margin: 0.5rem 0;
          }
        }
      }
    }

    .conts-choice {
      margin-bottom: 1rem;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .conts-choice-icon {
        width: 1.88rem;
        height: 1.5rem;
        margin-right: 0.4rem;
        margin-left: -0.8rem;
        border-top-right-radius: 1.05rem;
        border-bottom-right-radius: 1.05rem;
        background: linear-gradient(90deg, #e29a4a, #c07e28);
      }

      .conts-choice-text {
        color: #ca8700;
        font-size: 1.25rem;
        font-weight: 700;
      }
    }

    .conts-infos {
      padding: 0 0.9rem;

      .conts-infos-label {
        color: #1d1d1d;
        font-size: 1rem;
        margin-bottom: 1rem;
      }

      .conts-infos-phone {
        color: #222;
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }

    .conts-infos-button-sure {
      background-color: #d69d4c;
    }
    .conts-infos-button-unconfirmed {
      background-color: #a7a7a7;
    }

    .active {
      background-color: #a7a7a7;
    }

    .conts-infos-button {
      height: 2.8rem;
      line-height: 2.8rem;
      margin: 0 auto;
      text-align: center;
      border-radius: 2.25rem;

      span {
        font-size: 1.1rem;
        color: #fff;
      }
    }

    .conts-infos-account {
      margin-bottom: 1rem;
      padding-bottom: 0.3rem;
      border-bottom: 1px solid #ebebeb;
    }

    .conts-infos-label {
      color: #1d1d1d;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .conts-infos-account-input {
      border: 0;
      margin: 0;
      padding: 0;
      color: #222;
      font-size: 1.5rem;
      font-weight: 600;
      vertical-align: middle;
      outline: none;
    }
  }

  .rules {
    padding: 0 1.25rem;

    .rules-lists {
      padding-top: 1.5rem;

      .rules-title {
        color: #222;
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1rem;
      }

      .rules-text {
        .rules-txt {
          color: #666;
          font-size: 0.95rem;
          line-height: 1.6;
          margin-bottom: 0.25rem;
        }
        .txt-color {
          color: #bc7714;
        }
      }
    }
  }
}
.select-btn {
  margin-top: 1rem;
}
.van-popup--center {
  width: 84%;
  padding: 1rem;
  margin-top: -4.8rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  background-color: #fff;

  h2 {
    color: #222;
    font-size: 0.85rem;
    font-weight: 700;
    text-align: center;
    // margin: 0 auto .5rem;
    // margin-bottom: 0.5rem;
  }

  .content {
    padding: 0.9rem 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ebebeb;

    input {
      width: 100%;
      padding: 0;
      font-size: 1rem;
      text-align: center;
      vertical-align: middle;
      border: 0;
    }
  }

  .van-button--round {
    width: 100%;
    height: 2.8125rem;
    border: 0;
    border-radius: 2.25rem;
    font-size: 1rem;
    text-align: center;
  }
}
.online-service {
  // width: 100%;
  float: right;
  cursor: pointer;
  line-height: 1rem;
  font-size: 0.7rem;
  // margin-bottom: 1.3rem;
}
a {
  color: #0066ff;
}
.popipContent {
  //background: #666;
  width: 100%;
  .item {
    margin-bottom: 0.7rem;
    font-size: 1rem;
    color: #666;
  }
  .note {
    color: #b22222;
  }
  .title {
    color: #222;
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 1rem;
  }
  .remark {
    width: 85%;
    color: red;
    text-align: right;
    font-size: 0.5rem;
    margin-top: 1.4rem;
  }
  .btn {
    margin: 0 auto;
    width: 70%;
    //background: #666;
    padding: 0.2rem 1rem 1rem;
    display: flex;
    justify-content: space-between;
  }
}
</style>
