import Vue from 'vue'
        Vue.directive('preventReclick',{
            inserted(el,binding){
                el.addEventListener('click',() => {
                    if(!el.disabled){
                        el.disabled = true
                        el.style.background = "#a7a7a7"
                        setTimeout(() => {
                            el.disabled = false 
                            el.style.background = "#d69d4c"      
                        },binding.value || 3000)
                    }
                })
            }
        })
