<template>
  <div class="box">
    <img class="imgbg" src="../assets/image/bg.jpg" alt=""/>
    <div class="content-box">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index" @click="goto(index)">
          <img :src="image"/>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="record" @click="showPopup">购买须知</div>
    <van-popup v-model="showp">
      <h2 style="text-align: left">年卡：</h2>
      <h1>购买后有效期365天，可领取12次，每次领取间隔30天，每次领取任意一个会员月卡，有效期1个月</h1>
      <br>
      <h2 style="text-align: left"> 注意：所有卡种购买后到期自动结束，不可再领取</h2><br>
      <h2 style="text-align: left">客服电话:18666017481,服务时间:工作日9:00-18:00</h2>
    </van-popup>

    <div class="rules">
      <div class="rules-lists">
        <div class="rules-title">· 权益购买须知 ·</div>
        <div class="rules-text">
          <p class="rules-txt">
            1、每个手机号码限购1份，下单后立即激活会员特权。购买时务必填写核实正确的手机号码，如因手机号填写错误导致充值错误，由消费者自行承担。
          </p>
          <p class="rules-txt">2、本产品下单购买，不退不改，介意者勿拍！</p>
          <p class="rules-txt">
            3、下单前请您务必仔细阅读产品中各项说明，然后再下单订购。
          </p>
          <p class="rules-txt">
            4、下单购买后，则视为您已仔细阅读本产品中的各项说明，并认同此《购买须知》以及产品规则，否则责任自负，不在售后服务之列。
          </p>
        </div>
      </div>

      <div class="rules-lists">
        <div class="rules-title">使用规则</div>
        <div class="rules-text">
          <p class="rules-txt">
            1、使用规则:<br/>
            年卡：共有12个周期兑换VIP权益(即购买后12个月内有12次权益兑换机会)<br/>
            <!--            半年卡：共有6个周期兑换VIP权益(即购买后6个月内有6次权益兑换机会)<br/>
                        季卡：共有3个周期兑换VIP权益(即购买后3个月内有3次权益兑换机会)<br/>
                        月卡：共有1个周期兑换VIP权益(即购买后1个月内有1次权益兑换机会)<br/>
                        钻石版月卡：共4个周期兑换VIP权益(即购买后1个月内有4次权益兑换机会)<br/>
                        周卡：共有1个周期兑换VIP权益(即购买后1个星期内有1次权益兑换机会)<br/>-->
          </p>
          <p class="rules-txt">
            2、为确保个人权益与信息安全，在兑换权益周期内须本人填写【手机号码】+【验证码】的形式登录领取兑换；可以选择周期内任意一款产品，需每月验证本人使用去兑换，预期未兑换视为放弃兑换资格。
          </p>
        </div>
      </div>
      <div class="rules-lists">
        <div class="rules-title">· 注意事项 ·</div>
        <div class="rules-text">
          <p class="rules-txt">
            1、每个周期每次兑换会员时只能选择一个会员。
          </p>
          <p class="rules-txt">
            2、每次选择会员时务必填写购买时的手机号码; 腾讯视频、QQ音乐、QQ会员要求填写正确的QQ号码，该三项权益要求通过QQ账号登录。
          </p>
          <p class="rules-txt">
            3、本商品只能在手机、电脑、ipad上使用，均非TV版。
          </p>
          <p class="rules-txt">
            4、若24小时后还未激活，请及时联系客服。
          </p>
          <p class="rules-txt">
            5、每月N个会员可相互转换/持续兑换一种，每月限转1次。
          </p>
          <p class="rules-txt">
            6、仅限注册会员购买使用，无法为其他手机号购买，可领取的权益均非TV版。
          </p>
          <p class="rules-txt">
            7、套餐内容如有变动或需要套餐外增值服务，费用自理，详情请咨询客服。<br>
            客服电话:18666017481;服务时间:工作日9:00-18:00
          </p>
          <br/>
          <div class="rules-txt" style="text-align: center;">@再芮股份</div>
          <br/>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import Vue from 'vue';
import {Popup} from 'vant';
import {Dialog, Toast} from "vant";

Vue.use(Popup);
export default {
  name: "Login",
  computed: {
    ...mapState(["token", "eligibilityId", "phone"]),
  },
  data() {
    return {
      eId: null,
      showvalid: false,
      codeDisable: false,
      validateShow: true,
      validateCode: null,
      codeColor: "#a7a7a7",
      validatePay: false,
      showp: false,
      contentBox: {
        backgroundImage: "url(" + require("../assets/image/bg.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundColor: "#191a15",
      },
      // 进入页面用户名登录请求数据
      enterForm: {
        username: "zhixingtc",
      },
      showColor: "#d69d4c",
      loginPhone: "",
      disablebtn: true,
      // 立即领取按钮：
      loginForm: {
        token: "",
        phone: "",
      },
      // 存没有领取资格返回的数据列表
      dataList: [],
      images: [
        require("../assets/image/10unionpay_bg.png"),
      ],
      payForm: {
        token: '',
        phone: '',
        vipGroupBatch: ''
      },
      show: false,
      disable: false,
      showmanualService: false,
      validateForm: {
        token: "",
        phone: "",
        code: ""
      }
    };
  },
  methods: {
    validateMethod() {
      this.validateForm.phone = this.loginForm.phone;
      this.validateForm.code = this.validateCode;

      if (this.validate) {

        this.axios.post('/apiPhoneMessageRecord/validate', this.validateForm, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.data.data.code !== 51000) {
            Toast(res.data.data.message);
            this.disable = false;
            //验证码验证成功
          } else {
            Toast('验证成功');
            if (this.validatePay) {
              this.uniOrder();
            } else {
              this.showvalid = false;
              this.asyncSetPhone(this.loginForm.phone);
              this.asyncSetEligibilityId(this.eId);
              this.$router.push({path: "/main"});
            }
          }
        }).catch(error => {
          console.log(error)
        });
      }
    }, goToBuy() {
      this.axios
          .post(
              "/wechat/unionOrder", this.payForm,
              {
                headers: {"Content-Type": "application/json"},
              }
          )
          .then((res) => {
            if (res.data.data.code === 40000) {
              this.dataList = res.data.data;
              console.log(this.dataList);
              this.wechatPay();
              this.disable = false;
            } else {
              console.log(res)
              this.$notify(res.data.data.message);
              this.disable = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    showPopup() {
      this.showp = true;
    },
    ...mapActions(["asyncSetToken", "asyncSetEligibilityId", "asyncSetPhone"]),
    goto(index) {
      switch (index) {
        case 0 :
          window.location.href = "https://ysf.zairui-vip.com/?index=unionpay_year&version=2.3";  //添加版本号可以强制刷新缓存
          break;
      }
    },
    uniOrder() {
      this.payForm.token = this.token;
      this.payForm.phone = this.loginForm.phone;
      this.axios
          .post(
              "/wechat/unionOrder", this.payForm,
              {
                headers: {"Content-Type": "application/json"},
              }
          )
          .then((res) => {
            console.log(res);
            this.disable = false;
            if (res.data.data.code === 40000) {
              this.dataList = res.data.data;
              this.wechatPay();
            } else {
              this.$notify(res.data.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },

    confirm() {
      this.disable = true;
      let p = /^1[3456789]\d{9}$/;
      let result = this.loginForm.phone.length === 11 && p.test(this.loginForm.phone);
      if (!result) {
        this.$toast.fail("手机号码格式有问题，请检查");
        this.disable = false;
        return;
      }

      this.validateForm.phone = this.loginForm.phone;
      this.axios
          .post("/eligibility/validate", this.validateForm, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res.data.data.code !== 20000) {
              this.show = false;
              this.showvalid = true;
              this.validateShow = true;
              Toast("您还不是会员，验证验证码成功后跳转支付")
              this.validatePay = true;
              //发送验证码
              this.axios.post('/apiPhoneMessageRecord/send', this.validateForm, {
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then(res => {
                this.disable = false;
                if (res.data.data.code === 51000) {
                  this.validateShow = true;
                  Toast('验证码发送成功，5分钟有效');
                } else {
                  Dialog({message: res.data.data.message});
                }
              }).catch(error => {
                console.log(error);
              });
            } else {
              this.eId = res.data.data.detail.id;
              //发送验证码
              this.axios.post('/apiPhoneMessageRecord/send', this.validateForm, {
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then(res => {
                this.disable = false;
                if (res.data.data.code === 51000) {
                  this.validateShow = true;
                  Toast('验证码发送成功，5分钟有效');
                } else {
                  Dialog({message: res.data.data.message});
                }
              }).catch(error => {
                console.log(error);
              });
              this.show = false;
              this.showvalid = true;
              this.validateShow = true;
              Toast("输入验证码成功后跳转兑换页面");

            }
          });
    },
    wechatPay() {
      let thisOne = this;

      function onBridgeReady() {
        WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
              appId: thisOne.dataList.appid, //公众号ID，由商户传入
              timeStamp: thisOne.dataList.timeStamp + "", //时间戳，自1970年以来的秒数
              nonceStr: thisOne.dataList.nonceStr, //随机串
              package: thisOne.dataList.package,
              signType: thisOne.dataList.signType, //微信签名方式：
              paySign: thisOne.dataList.paySign, //微信签名
            },
            function (res) {
              console.log(res);
              if (res.err_msg === "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                Toast('支付成功，请刷新页面');
              }
            }
        );
      }

      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
              "WeixinJSBridgeReady",
              onBridgeReady(),
              false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady());
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady());
        }
      } else {
        onBridgeReady();
      }
    },
  },
  watch: {
    validateCode(newCode) {
      newCode.length === 6 ? (this.codeColor = "#d69d4c", this.validate = true) : (this.codeColor = "#a7a7a7", this.validate = false);
    }
  },
};
</script>

<style lang="scss" scoped>

.record {
  position: fixed;
  right: 0;
  top: 1.1rem;
  color: #fff;
  padding: 0.3rem 0.55rem 0.3rem 0.3rem;
  font-size: 0.6rem;
  background-color: rgba(126, 88, 42, 0.7);
  border-top-left-radius: 2.5rem;
  border-bottom-left-radius: 2.5rem;
}

.manualService {
  position: fixed;
  right: 0;
  top: 30.0rem;
  padding: 0.3rem 0.55rem 0.3rem 0.3rem;
  font-size: 0.6rem;
}

.manualService .imgms {
  width: 40px;
  height: 40px;
}


.box {
  width: 100%;
  box-sizing: border-box;

  .imgbg {
    width: 100%;
    height: 100vh;
    position: relative;
  }

  .content-box {
    width: 85%;
    position: absolute;
    top: 35%;
    left: 7.5%;

    img {
      width: 100%;
      height: 10%;
    }
  }

  .rules {
    background: #fff5ea;
    padding: 0 1.25rem;

    .rules-lists {
      padding-top: 1.5rem;

      .rules-title {
        color: #222;
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1rem;
      }

      .rules-text {
        .rules-txt {
          color: #666;
          font-size: 0.95rem;
          line-height: 1.6;
          margin-bottom: 0.25rem;
        }

        .txt-color {
          color: #bc7714;
        }
      }
    }
  }


  .van-popup--center {
    width: 84%;
    padding: 1rem;
    margin-top: -3rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    background-color: #fff;

    h2 {
      color: #222;
      font-size: 0.85rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 0.5rem;
    }

    .content {
      padding: 0.9rem 0;
      margin-bottom: 1rem;
      border-bottom: 1px solid #ebebeb;

      input {
        width: 100%;
        padding: 0;
        font-size: 1rem;
        text-align: center;
        vertical-align: middle;
        border: 0;
      }
    }

    .van-button--round {
      width: 100%;
      height: 2.8125rem;
      border: 0;
      border-radius: 2.25rem;
      font-size: 1rem;
      text-align: center;
    }
  }
}
</style>
